.App {
  text-align: center;
}
body {
  overflow-x: hidden;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.export {
  width: 100%;
  margin: 0px;
}
.m-r-0 {
  margin-right: 0px !important;
}
.page-size {
  padding: 9px 0px;
  margin: 0px -17px;
}
.mr-1 {
  margin-right: 8px;
}
.m-t-22 {
  margin-top: 22px;
}
.tile-report {
  padding: 20px 2px !important;
}

.tile-ul {
  margin: auto -18px !important;
}

.text-dark {
  color: #322929;
  font-size: 15px;
}

.export {
  width: 100%;
  margin: 0px;
}

.input-group-addons {
  padding: 3px 12px !important;
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 1 !important;
  color: #555555 !important;
  text-align: center !important;
  background-color: #eeeeee !important;
  border: 1px solid #ebebeb !important;
  border-radius: 3px !important;
}
.loaderBackground {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.faiconcat6 {
  padding: 8px 10px;
}
.h-131{
  height:131px
}
.h-93{
  height:93%
}
.h-200{
  height:200px
}
.h-100{
  height:100%
}
.react-datepicker__input-container input {
  width: 100% !important;
  border: 1px solid #ebebeb;
  height: 31px;
  padding: 6px 12px;
}

.m-r-0 {
  margin-right: 0px !important;
}
.page-size {
  padding: 9px 0px;
  margin: 0px -17px;
}
.radio-margin-right {
  margin-right: 10px;
}
.errorClass {
  color: #cc2424;
  display: inline-block;
  margin-top: 5px;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}

.loaderBackground {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.select-box-height {
  height: 38px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.date-picker {
  height: 38px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 4px solid #070606;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 15%;
  left: 50%;
  z-index: 9999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.cirtAreaSelectBox {
  position: relative;
  box-sizing: border-box;
  width: 76% !important;
}
.tags {
  margin: 0px 8px 5px 8px;
}
.edit {
  cursor: pointer;
}

.errorClass {
  color: #cc2424;
  display: inline-block;
  margin-top: 5px;
}
.App-link {
  color: #61dafb;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.inner-routing-component {
  padding: 20px;
}

#root {
  height: 100%;
}

.saveInformationButton {
  position: absolute;
  top: 6.2%;
  left: 72%;
}
